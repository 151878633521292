package dev.bnorm.elevated.model.auth

enum class Authority(
    vararg inherits: Authority
) {
    ACTIONS_READ,
    ACTIONS_WRITE(ACTIONS_READ),
    ACTIONS_ADMIN(ACTIONS_WRITE),

    CHARTS_READ,
    CHARTS_WRITE(CHARTS_READ),
    CHARTS_ADMIN(CHARTS_WRITE),

    DEVICES_READ,
    DEVICES_WRITE(DEVICES_READ),
    DEVICES_ADMIN(DEVICES_WRITE),

    NOTIFICATIONS_READ,
    NOTIFICATIONS_WRITE(NOTIFICATIONS_READ),
    NOTIFICATIONS_ADMIN(NOTIFICATIONS_WRITE),

    PUMPS_READ,
    PUMPS_WRITE(PUMPS_READ),
    PUMPS_ADMIN(PUMPS_WRITE),

    READINGS_READ,
    READINGS_WRITE(READINGS_READ),
    READINGS_ADMIN(READINGS_WRITE),

    SENSORS_READ,
    SENSORS_WRITE(SENSORS_READ),
    SENSORS_ADMIN(SENSORS_WRITE),

    USERS_READ,
    USERS_WRITE(USERS_READ),
    USERS_ADMIN(USERS_WRITE),

    ;

    val inherits: Set<Authority> = setOf(*inherits)
}
